import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
} from "@react-pdf/renderer";
import fontHeavy from "./DINNextLTPro-Heavy.ttf";
import fontRegular from "./DINNextLTPro-Regular.ttf";
import Html from "react-pdf-html";
const stylesheet = {
  // clear margins for all <p> tags
  p: {
    fontSize: 11,
    margin: 0,
    fontFamily: "DIN Next LT Pro",
    fontWeight: 400,
  },
  strong: {
    fontSize: 11,
    margin: 0,
    fontFamily: "DIN Next LT Pro",
    fontWeight: 400,
    marginBottom: 0,
    color: "#e90008",
  },
  ul: {
    fontSize: 11,
    margin: 0,
    fontFamily: "DIN Next LT Pro",
    fontWeight: 400,
    marginBottom: 10,
    marginLeft: 0,
    paddingLeft: 0,
    margin: "0",
    marginTop: 3,
  },
  li: {
    paddingLeft: "10",
  },
};
Font.register({
  family: "DIN Next LT Pro",
  fonts: [
    { src: fontRegular, fontWeight: 400 },
    { src: fontHeavy, fontWeight: 900 },
  ],
});
const Seite = (props) => {
  Font.register({
    family: "DIN Next LT Pro",
    fonts: [
      { src: fontRegular, fontWeight: 400 },
      { src: fontHeavy, fontWeight: 900 },
    ],
  });
  const styles = StyleSheet.create({
    page: {
      color: "black",
      flexDirection: "column",

      marginTop: 20,
      marginBottom: 20,
    },
    title: {
      fontFamily: "DIN Next LT Pro",
      fontWeight: 900,
      fontSize: 38,
      maxWidth: "65%",
      lineHeight: 1,
      minHeight: 80,
    },
    meta: {
      fontSize: 11,
      fontFamily: "DIN Next LT Pro",
      fontWeight: 900,
      color: "#e90008",
      marginBottom: 5,
    },
    section: {
      margin: 0,
      padding: 0,
      marginBottom: 20,
      marginLeft: 40,
      marginRight: 40,
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
    image: {
      width: 30,
      height: 30,
      left: 50,
    },
    tabelle: {
      flexDirection: "row",
      fontFamily: "DIN Next LT Pro",
      fontWeight: 400,
      fontSize: 11,
      flexWrap: "wrap",
      marginLeft: "-5",
      marginRight: "-5",
    },
    cell_20: {
      flexBasis: "23%",
      borderTop: "1px solid #ccc",
      borderBottom: "1px solid #ccc",
      paddingTop: 10,
      paddingBottom: 5,
      borderRight: "1px solid #ccc",
      paddingLeft: 5,
    },
    cell_15: {
      flexBasis: "20%",
      borderTop: "1px solid #ccc",
      borderBottom: "1px solid #ccc",
      paddingTop: 10,
      paddingBottom: 5,
      borderRight: "1px solid #ccc",
      paddingLeft: 5,
    },
    cell_10: {
      flexBasis: "10%",
      borderTop: "1px solid #ccc",
      borderBottom: "1px solid #ccc",
      paddingTop: 10,
      paddingBottom: 5,
      borderRight: "1px solid #ccc",
      paddingLeft: 5,
    },
    cell_5: {
      flexBasis: "7%",
      borderTop: "1px solid #ccc",
      borderBottom: "1px solid #ccc",
      paddingTop: 10,
      paddingBottom: 5,
      borderRight: "1px solid #ccc",
      paddingLeft: 5,
    },
    cell_last: {
      flexBasis: "20%",
      borderTop: "1px solid #ccc",
      borderBottom: "1px solid #ccc",
      paddingTop: 10,
      paddingBottom: 5,
      paddingLeft: 5,
    },
    cell_2: {
      flexBasis: "20%",
      paddingTop: 10,
      paddingBottom: 5,
      borderRight: "1px solid #ccc",
      paddingLeft: 5,
    },
    cell_2_large: {
      flexBasis: "23%",
      paddingTop: 10,
      paddingBottom: 5,
      borderRight: "1px solid #ccc",
      paddingLeft: 5,
    },
    cell_2_small: {
      flexBasis: "7%",
      paddingTop: 10,
      paddingBottom: 5,
      borderRight: "1px solid #ccc",
      paddingLeft: 5,
    },
    cell_2_medium: {
      flexBasis: "10%",
      paddingTop: 10,
      paddingBottom: 5,
      borderRight: "1px solid #ccc",
      paddingLeft: 5,
    },
    cell_2_last: {
      flexBasis: "20%",
      paddingTop: 10,
      paddingBottom: 5,
      paddingLeft: 5,
    },
    spalten: {
      flexDirection: "row",
      marginBottom: 20,
      marginLeft: 40,
      marginRight: 40,
    },
    text: {
      fontFamily: "DIN Next LT Pro",
      fontWeight: 400,
      fontSize: 11,
      marginBottom: 10,
    },
    spalte_8: {
      flexBasis: "65%",
      paddingRight: 20,
    },
    spalte_4: {
      flexBasis: "35%",
    },
    image: {
      width: 40,
      height: 40,
    },
    footer: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      fontFamily: "DIN Next LT Pro",
      fontWeight: 400,
      fontSize: 8,
    },
    academyLogo: {
      width: "100",
      position: "absolute",
      top: 0,
      right: 40,
    },
    swissCyclingLogo: {
      width: 45,
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 5,
    },
    platzhalter: {
      height: 10,
      marginBottom: 10,
      borderBottom: "1px solid #ccc",
    },
    loading: {
      height: "100vh",
      width: "100%",
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
    },
  });

  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.meta}>
          {props.language === "de"
            ? "Üben, Spielen, Velofahren"
            : "Pratiquer, jouer, faire du vélo"}
        </Text>
        <Text style={styles.title}>{props.title}</Text>
      </View>
      <View style={styles.academyLogo}>
        <Image src="/academy-logo.png" />
      </View>
      <View style={styles.section}>
        <Text style={styles.meta}>
          {props.language === "de" ? "Kriterien" : "Critères"}
        </Text>
        <View style={styles.tabelle}>
          <View style={styles.cell_20}>
            <Text>{props.language === "de" ? "Technik" : "Technique"}</Text>
          </View>
          <View style={styles.cell_10}>
            <Text>{props.language === "de" ? "Stufe" : "Niveau"}</Text>
          </View>
          <View style={styles.cell_5}>
            <Text>{props.language === "de" ? "Zeit" : "Durée"}</Text>
          </View>
          <View style={styles.cell_15}>
            <Text>{props.language === "de" ? "Terrain" : "Terrain"}</Text>
          </View>
          <View style={styles.cell_15}>
            <Text>{props.language === "de" ? "Material" : "Matériel"}</Text>
          </View>
          <View style={styles.cell_last}>
            <Text>
              {props.language === "de" ? "Gruppengrösse" : "Taille du groupe"}
            </Text>
          </View>
          <View style={styles.cell_2_large}>{props.technik}</View>
          <View style={styles.cell_2_medium}>{props.stufe}</View>
          <View style={styles.cell_2_small}>
            <Text>{props.zeit}</Text>
          </View>
          <View style={styles.cell_2}>
            <Text>{props.terrain}</Text>
          </View>
          <View style={styles.cell_2}>
            <Text>{props.material}</Text>
          </View>
          <View style={styles.cell_2_last}>
            <Text>{props.min + "-" + props.max}</Text>
          </View>
        </View>
      </View>
      <View style={styles.spalten}>
        <View style={styles.spalte_8}>
          <Text style={styles.meta}>{props.language === "de" ? "Kurzinfo" : "En bref"}</Text>
          <Text style={styles.text}>{props.kurzinfo}</Text>
          <View style={styles.imageWrapper}>
            <Image src={props.bild} />
          </View>
          <Text style={styles.meta}>{props.language === "de" ? "Beschreibung" : "Description"}</Text>
          <Html stylesheet={stylesheet}>{props.beschreibung}</Html>
        </View>
        <View style={styles.spalte_4}>
          <Text style={styles.meta}>{props.language === "de" ? "Tipp" : "Astuce"}</Text>
          <Text style={styles.text}>{props.tipp}</Text>
          <Text style={styles.meta}>{props.language === "de" ? "Variationsmöglichkeiten" : "Possibilités de variantes"}</Text>
          <Html resetStyles stylesheet={stylesheet}>
            {props.variation}
          </Html>
        </View>
      </View>
      <View style={styles.section}>
        <Text style={styles.meta}>{props.language === "de" ? "Notizen" : "Remarques"}</Text>
        <Text style={styles.platzhalter}></Text>
        <Text style={styles.platzhalter}></Text>
        <Text style={styles.platzhalter}></Text>
      </View>
      <View style={styles.footer} fixed>
        <View style={styles.swissCyclingLogo}>
          <Image src="/swiss-cycling-logo.png" />
        </View>
        <Text>Sportstrasse 44 2540 Grenchen swiss-cycling.ch</Text>
      </View>
    </Page>
  );
};

export default Seite;
